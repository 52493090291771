import { SET_PERSONALISED_PORTFOLIO_DATA } from "./actions";

const initialState = {
    companies: {},
    investmentHistory: {
      dates: [],
      values: [],
    },
    ratios: [],
  };

const personalisedPortReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PERSONALISED_PORTFOLIO_DATA:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default personalisedPortReducer;
