import React, { useState } from 'react';
import {
  Box,
  Flex,
  IconButton,
  HStack,
  Progress,
  Text,
} from '@chakra-ui/react';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import valueMappingQuestions from './components/valueMappingQuestions';

import McqTypeQuestions from 'components/value-mapping/components/McqTypeQuestions';
import TextTypeQuestions from 'components/value-mapping/components/TextTypeQuestions';
import SelectTypeQuestions from 'components/value-mapping/components/SelectTypeQuestions';
import RatingTypeQuestions from 'components/value-mapping/components/RatingTypeQuestions';
import McqFollowUpQuestions from 'components/value-mapping/components/McqFollowUpQuestions';
import { AvenirLogo, AvenirWhiteLogo } from 'components/icons/Icons';
import { getFirestore, setDoc, doc } from 'firebase/firestore';
// value mapping context
const initialState = {
  1: { id: 1, question: 'Question 1', answers: [] },
  2: { id: 2, question: 'Question 2', answers: [] },
  3: { id: 3, question: 'Question 3', answers: [] },
  4: { id: 4, question: 'Question 4', answers: [] },
  5: { id: 5, question: 'Question 5', answers: [] },
  6: { id: 6, question: 'Question 6', answers: [] },
  7: { id: 7, question: 'Question 7', answers: [] },
  8: { id: 8, question: 'Question 8', answers: [] },
  9: { id: 9, question: 'Question 9', answers: [] },
  10: { id: 10, question: 'Question 10', answers: [] },
  11: { id: 11, question: 'Question 11', answers: [] },
  12: { id: 12, question: 'Question 12', answers: [] },
  13: { id: 13, question: 'Question 13', answers: [] },
  14: { id: 14, question: 'Question 14', answers: [] },
  15: { id: 15, question: 'Question 15', answers: [] },
};
export default function Default({ successState, setSuccessState }) {
  const [vMapQuestionsContext, setvMapQuestionsContext] =
    useState(initialState);
  const questions = valueMappingQuestions.valueMappingQuestions;
  const questionLength = questions.length;
  const [questionNumber, setQuestionNumber] = useState(1);
  const increaseNumber = () => {
    if (questionNumber !== 11) {
      if (vMapQuestionsContext[questionNumber].answers.length > 0) {
        setQuestionNumber((prevNumber) =>
          prevNumber < questionLength ? prevNumber + 1 : prevNumber,
        );
      }
    }
    if (questionNumber === 11) {
      if (vMapQuestionsContext[questionNumber].answers.length > 1) {
        setQuestionNumber((prevNumber) =>
          prevNumber < questionLength ? prevNumber + 1 : prevNumber,
        );
      }
    }
    if (questionNumber === questionLength) {
      setSuccessState(true);
      const db = getFirestore();
      const docRef = doc(db, 'valueMapResponse', vMapQuestionsContext[1].answers[0]);
      const payload = vMapQuestionsContext;
      setDoc(docRef, payload);
    }
  };
  const decreaseNumber = () => {
    setQuestionNumber((prevNumber) =>
      prevNumber > 1 ? prevNumber - 1 : prevNumber,
    );
  };
  const progressValue = (questionNumber / questionLength) * 100;
  const updatevMapQuestionsContext = (id, question, answers) => {
    if (id === '6' && answers !== vMapQuestionsContext[6].answers) {
      setvMapQuestionsContext((prevQuestions) => ({
        ...prevQuestions,
        '7': { ...prevQuestions['7'], question, answers: [] },
      }));
    }
    setvMapQuestionsContext((prevQuestions) => ({
      ...prevQuestions,
      [id]: { ...prevQuestions[id], question, answers },
    }));
  };
  return (
    <Box w={{ base: '100vw', xl: '60vw' }}>
      <Progress
        value={progressValue}
        w="100%"
        ml="-20px"
        zIndex="-1"
        h="10px"
      />
      <Flex
        sx={{ textStyle: 'regular' }}
        fontWeight="bold"
        pl={5}
        pr={{ base: 5, xl: 3}}
        width={{ base: '100%', xl: '99%' }}
        justifyContent={{ base: 'space-between', xl: 'end' }}
        alignItems="center"
        mt={{ base: '3vh', xl: '5vh' }}
        marginRight={{xl: '15px' }}
        fontSize='18px'
      >
        <AvenirLogo
          h="36px"
          w="128px"
          display={{ base: 'block', xl: 'none' }}
        />
        {questionNumber}/{questionLength}
      </Flex>
      <Flex justifyContent="center" width="full">
        {questions[questionNumber - 1].type === 'McqTypeQuestions' ? (
          <McqTypeQuestions
            valueProps={{ vMapQuestionsContext, updatevMapQuestionsContext }}
            valueQuestion={questions[questionNumber - 1]}
          />
        ) : null}
        {questions[questionNumber - 1].type === 'TextTypeQuestions' ? (
          <TextTypeQuestions
            valueProps={{ vMapQuestionsContext, updatevMapQuestionsContext }}
            valueQuestion={questions[questionNumber - 1]}
          />
        ) : null}
        {questions[questionNumber - 1].type === 'SelectTypeQuestions' ? (
          <SelectTypeQuestions
            valueProps={{ vMapQuestionsContext, updatevMapQuestionsContext }}
            valueQuestion={questions[questionNumber - 1]}
          />
        ) : null}
        {questions[questionNumber - 1].type === 'RatingTypeQuestions' ? (
          <RatingTypeQuestions
            valueProps={{ vMapQuestionsContext, updatevMapQuestionsContext }}
            valueQuestion={questions[questionNumber - 1]}
          />
        ) : null}
        {questions[questionNumber - 1].type === 'McqTypeQuestionsFollowUp' ? (
          <McqFollowUpQuestions
            valueProps={{ vMapQuestionsContext, updatevMapQuestionsContext }}
            valueQuestion={questions[questionNumber - 1]}
          />
        ) : null}
      </Flex>
      <Flex
        position="fixed"
        width={{ base: '100%', xl: '10px' }}
        left={{ base: '0', xl: 'auto' }}
        right={{ xl: '15px', base: 'auto' }}
        justifyContent="space-between"
        bottom="0"
        p={{ xl: '0 96px 44px 0', base: '5' }}
        backgroundColor='white'
      >
        <HStack>
          <IconButton
            aria-label="Up"
            icon={<ArrowBackIcon />}
            variant="outline"
            colorScheme="purple"
            isRound={true}
            onClick={decreaseNumber}
            disabled={questionNumber === 1}
          />
          <IconButton
            aria-label="Down"
            icon={<ArrowForwardIcon />}
            colorScheme="purple"
            variant="solid"
            isRound={true}
            backgroundColor="avenirPurple.700"
            onClick={increaseNumber}
            disabled={questionNumber === questionLength}
          />
        </HStack>
        <Box
          display={{ base: 'flex', xl: 'none' }}
          w="172px"
          h="32px"
          borderRadius="24px"
          justifyContent="space-around"
          alignItems="center"
          bg="avenirBlack"
          p="2px"
          mt='5px'
        >
          <Text sx={{ textStyle: 'small' }} pt='2px' color="avenirWhite">
            Powered by
          </Text>
          <AvenirWhiteLogo h="16px" w="78px" />
        </Box>
      </Flex>
    </Box>
  );
}
