import { SET_FINANCIAL_DATA } from './actions';

const initialState = {
    companies: {},
    investmentHistory: {
      dates: [],
      values: [],
    },
    ratios: [],
  };

const financialDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FINANCIAL_DATA:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default financialDataReducer;
