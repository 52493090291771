import React, { useState, useEffect } from 'react';
import {
  ChakraProvider,
  Grid,
  Box,
  Text,
  VStack,
  theme,
  useMultiStyleConfig,
  useCheckboxGroup,
} from '@chakra-ui/react';
import { Icon } from '@chakra-ui/icons';
// import icons
import { GiCow, GiWindTurbine,GiEarthAfricaEurope } from "react-icons/gi";
import { IoWaterOutline, IoTrashOutline, IoEarthOutline, IoHelpBuoyOutline } from "react-icons/io5";
import { CiMedicalMask } from "react-icons/ci";
import { PiPlant, PiGenderIntersex } from "react-icons/pi";
import { MdOutlineAgriculture, MdOutlineHowToVote, MdOutlineHealthAndSafety, MdDiversity2 } from "react-icons/md";
import { LiaHandHoldingHeartSolid, LiaBalanceScaleSolid } from "react-icons/lia";
import { LuBookCopy } from "react-icons/lu";
import { FaPersonWalkingDashedLineArrowRight } from "react-icons/fa6";
import { GrMoney } from "react-icons/gr";
import { FaRegHandshake } from "react-icons/fa";
import { RiBankLine } from "react-icons/ri";
import { VscWorkspaceTrusted } from "react-icons/vsc";
import { BsPeace } from "react-icons/bs";

const environmentalIcons = [
  { icon: GiCow },
  { icon: IoWaterOutline },
  { icon: GiWindTurbine },
  { icon: GiEarthAfricaEurope },
  { icon: CiMedicalMask },
  { icon: IoTrashOutline },
  { icon: PiPlant },
  { icon: MdOutlineAgriculture },
];
const socialIcons = [
  { icon: IoEarthOutline },
  { icon: PiGenderIntersex },
  { icon: LiaHandHoldingHeartSolid },
  { icon: LiaBalanceScaleSolid },
  { icon: LuBookCopy },
  { icon: MdOutlineHowToVote },
  { icon: FaPersonWalkingDashedLineArrowRight },
  { icon: GrMoney },
  { icon: MdOutlineHealthAndSafety },
];
const governanceIcons = [
  { icon: MdDiversity2 },
  { icon: FaRegHandshake },
  { icon: RiBankLine },
  { icon: VscWorkspaceTrusted },
  { icon: BsPeace },
  { icon: IoHelpBuoyOutline },
];
function TopicsSelection({ valueProps, valueQuestion }) {
  const { vMapQuestionsContext, updatevMapQuestionsContext } = valueProps;
  const { getCheckboxProps } = useCheckboxGroup();
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [selectedColor, setselectedColor] = useState('#E7D6FF');
  const [iconColor, seticonColor] = useState('#B17EFF');
  const val = [];
  const [nOfSelect, setNOfSelect] = useState(valueQuestion.id < 11 ? 3 : 2);
  const instructionByQuestionId = {
    11: "Select up to 2 to 3 topics that you would like to prioritize",
    12: "Select up to 3 topics that you would like to prioritize",
    13: "Select up to 2 topics that you would like to prioritize"
  };

  const handleTopicSelect = (topicId) => {
    setSelectedTopics((prevSelectedTopics) => {
      if (prevSelectedTopics.includes(topicId)) {
        return prevSelectedTopics.filter((id) => id !== topicId);
      } else {
        return [...prevSelectedTopics, topicId].slice(-nOfSelect);
      }
    });
  };
  const esgIcons = (index) => {
    if (valueQuestion.question === 'Environmental') {
     return environmentalIcons[index].icon
    } else if (valueQuestion.question === 'Social') {
      return socialIcons[index].icon
    } else {
      return governanceIcons[index].icon
    }
  };
  
  useEffect(() => {
    valueQuestion.answers.forEach((ans) => {
      if (selectedTopics.includes(ans.id)) {
        val.push(ans.value);
      }
    });
    updatevMapQuestionsContext(valueQuestion.id, valueQuestion.question, val);
    setNOfSelect(valueQuestion.id < 13 ? 3 : 2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTopics, valueQuestion.id]);
  useEffect(() => {
    let idVal = [];
    valueQuestion.answers.forEach((idvals) => {
      if (
        vMapQuestionsContext[valueQuestion.id].answers.includes(idvals.value)
      ) {
        idVal.push(idvals.id);
      }
    });
    setSelectedTopics(idVal);
    if (valueQuestion.question === 'Environmental') {
      setselectedColor('#F2E9FE');
      seticonColor('#8247FF');
    } else if (valueQuestion.question === 'Social') {
      setselectedColor('#FFF0D4');
      seticonColor('#FEB732');
    } else {
      setselectedColor('#FFE3D4');
      seticonColor('#FA6B25');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueQuestion.question]);
  const styles = useMultiStyleConfig('CustomCheckbox', {});

  return (
    <ChakraProvider theme={theme}>
      <VStack
        spacing={{ base: '1', xl: '4' }}
        align="stretch"
        p={5}
        width={{ base: '100%', xl: '70%' }}
      >
        <Text
          fontWeight="bold"
          sx={{
            textStyle: { base: 'popinsmallHeading', xl: 'popinlarge' },
          }}
          fontSize="18px"
        >
          {
            instructionByQuestionId[valueQuestion.id]
          }
        </Text>
        <Text fontSize="xl" fontWeight="semibold" mt="24px" mb={4}>
          {valueQuestion.question}
        </Text>
        <Grid
          templateColumns="repeat(2, 1fr)"
          gap={{ base: '4', xl: '4' }}
          maxHeight="64vh"
          overflow="scroll"
          width="99%"
          pb={{ base: '80px', xl: '0' }}
        >
          {valueQuestion.answers.map((topic, index) => (
            <Box
              as="button"
              key={topic.id}
              {...getCheckboxProps({ value: topic.id })}
              onClick={() => handleTopicSelect(topic.id)}
              borderWidth="1px"
              borderRadius="24px"
              p={{ base: '2', xl: '3' }}
              d="flex"
              alignItems="center"
              justifyContent="space-between"
              bgGradient={
                selectedTopics.includes(topic.id)
                  ? `linear(to-t, ${selectedColor}, ${selectedColor})`
                  : `linear(to-t, ${selectedColor} 12px, white 12px)`
              }
              borderColor={
                selectedTopics.includes(topic.id) ? iconColor : iconColor
              }
              // _hover={{
              //   bg: selectedColor,
              //   borderColor: selectedColor,
              // }}
              width={{ base: '100%', md: '100%', xl:'100%' }}
              height={{ base: '12vh', md: '14vh', xl: '14vh' }}
              sx={styles.container}
            >
              <Icon
                as={esgIcons(index)}
                w={{ base: 6, xl: 8, '2xl': 10 }}
                h={{ base: 6,  xl: 8, '2xl': 10 }}
                color={selectedTopics.includes(topic.id) ? iconColor :'avenirBlack' }
              />
              <Text
                sx={{
                  textStyle: { base: 'small', xl: 'medium' },
                }}
              >
                {topic.value}
              </Text>
            </Box>
          ))}
        </Grid>
      </VStack>
    </ChakraProvider>
  );
}

export default TopicsSelection;
