import { Text } from "@chakra-ui/react";
export default function KpiRatiosOptions(props) {
    const { isGrowth,value } = props;
    const colorGrowth = isGrowth ? 'avenirGreen.600' : 'avenirRed.600';
    return(
        <>
            <Text
                color={colorGrowth} 
                sx={{textStyle: 'medium'}}
            >
                {value}%
            </Text>
        </>
    );
}