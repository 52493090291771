import React from 'react';
import { ClientsIcon, AccountIcon, PortfolioIcon, AssetsIcon } from 'components/icons/Icons';

export function AvenirSidebarLink() {
  return (
    <>
    <div style={{ display: 'flex', alignItems: 'center', margin:"0 0 15px 0", cursor:'pointer'}}>
      <ClientsIcon height="28px" width="28px"/>
      <span style={{ marginLeft: '10px', fontSize: "18px", fontWeight: 'bold'}}>Clients</span>
    </div>
    <div  style={{ display: 'flex', alignItems: 'center', margin:"0 0 15px 0", cursor:'pointer' }}>
      <AssetsIcon height="28px" width="28px"/>
      <span style={{ marginLeft: '10px', fontSize: "18px", fontWeight: 'bold', color: 'gray' }}>Assets</span>
    </div>
    <div  style={{ display: 'flex', alignItems: 'center', margin:"0 0 15px 0", cursor:'pointer' }}>
      <PortfolioIcon height="28px" width="28px"/>
      <span  style={{ marginLeft: '10px', fontSize: "18px", fontWeight: 'bold', color: 'gray' }}>Portfolio</span>
    </div>
    <div  style={{ display: 'flex', alignItems: 'center', margin:"0 0 15px 0", cursor:'pointer' }}>
      <AccountIcon height="28px" width="28px"/>
      <span style={{ marginLeft: '10px', fontSize: "18px", fontWeight: 'bold', color: 'gray' }}>Account</span>
    </div>
    </>
  );
}


export default AvenirSidebarLink;
