import React, { useEffect, useState } from 'react';
import { Box, Checkbox, CheckboxGroup, Stack, Text } from '@chakra-ui/react';

function McqTypeQuestions({ valueProps, valueQuestion }) {
  const { vMapQuestionsContext, updatevMapQuestionsContext } = valueProps;
  const [checkedItems, setCheckedItems] = useState(
    vMapQuestionsContext[valueQuestion.id].answers,
  );
  const VMapQuestion = valueQuestion;
  const handleCheckChange = (value) => {
    if (valueQuestion.id === '9') {
      setCheckedItems(currentItems => {
        const isValuePresent = currentItems.includes(value);
        const newCheckedItems = isValuePresent
          ? currentItems.filter(item => item !== value) 
          : [...currentItems, value]; 
        updatevMapQuestionsContext(valueQuestion.id, valueQuestion.question, newCheckedItems);
        return newCheckedItems;
      });
    } else {
      setCheckedItems([value]);
      updatevMapQuestionsContext(valueQuestion.id, valueQuestion.question, [
        value,
      ]);
    }
  };
  useEffect(() => {
    setCheckedItems(vMapQuestionsContext[valueQuestion.id].answers);
  }, [vMapQuestionsContext, valueQuestion.id]);
  return (
    <Box p={4} width={{ base: '100%', xl: '75%' }} mt="5px">
      <Text
        mb={{ base: '24px', xl: '24px' }}
        sx={{
          textStyle: { base: 'popinsmallHeading', xl: 'popinlarge' },
        }}
        fontSize="18px"
      >
        {VMapQuestion.question}
      </Text>
      <CheckboxGroup
        value={checkedItems}
        mt="25px"
      >
        <Stack direction="column" rowGap="5px" h="65vh" overflow="scroll" pb={{ base: '80px', xl: '0' }}>
          {VMapQuestion.answers.map((answer, index) => (
            <Checkbox
              key={answer.id}
              value={answer.value}
              border="1px solid"
              borderColor="avenirPurple.600"
              width={{ base: '95%', xl: '85%' }}
              p={{ base: '16px 12px 16px 18px', xl: '20px 0 20px 30px' }}
              borderRadius="24px"
              onChange={() => handleCheckChange(answer.value)}
              sx={{ textStyle: 'popinsmall' }}
              _checked={{
                bg: 'avenirPurple.50',
                borderColor: 'purple.600',
                iconColor: 'purple.600',
              }}
              _focus={{
                boxShadow: 'none',
              }}
              colorScheme="purple"
              spacing={{base: '18px', xl: '32px'}}
            >
              {answer.value}
            </Checkbox>
          ))}
        </Stack>
      </CheckboxGroup>
    </Box>
  );
}

export default McqTypeQuestions;
