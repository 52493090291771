import React from 'react';
import { Flex, Text, Box, Heading } from '@chakra-ui/react';
import { AvenirWhiteLogo } from 'components/icons/Icons';

function SucessQuestion() {
  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      h="100vh"
      w="100vw"
      bg="black"
      color="white"
      p={{ base: 4, md: 8 }}
    >
      <Box as="header">
        <Heading size="lg" mb={4} position="fixed" top="10vh" left="8vw">
          <AvenirWhiteLogo h="46px" w="210px" />
        </Heading>
      </Box>

      <Text
        fontSize={{ base: '2xl', md: '4xl', lg: '6xl' }}
        fontWeight="400"
        color="avenirWhite"
        sx={{ textStyle: 'popinextralarge' }}
        textAlign="center"
      >
        You’ve done the first step
      </Text>
      <Text
        fontSize={{ base: '2xl', md: '4xl', lg: '6xl' }}
        mt="15px"
        fontWeight="400"
        color="avenirWhite"
        sx={{ textStyle: 'popinextralarge' }}
      >
        to invest{' '}
        <Text
          as="span"
          fontSize={{ base: '2xl', md: '4xl', lg: '6xl' }}
          bg="avenirPurple.600"
          p="10px"
          color="avenirWhite"
          borderRadius="24px"
          sx={{ textStyle: 'popinextralarge' }}
          fontWeight="bold"
        >
          with purpose!
        </Text>
      </Text>
    </Flex>
  );
}

export default SucessQuestion;
