import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Flex,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';

function CompanyDetails({CompanyDetails}) {
  const itemsPerPage = 5;
  const personalisedPortData = useSelector((state) => state);
  const [isCompanyDetails, setIsCompanyDetails] = useState(CompanyDetails);

  // State to track current page for each sector
  const [currentPages, setCurrentPages] = useState(Object.keys(isCompanyDetails).reduce((acc, _, index) => {
    acc[index] = 1; // Initialize each sector's page to 1
    return acc;
  }, {}));

  // Function to change page for a sector
  const setPage = (sectorIndex, pageNumber) => {
    setCurrentPages((prev) => ({
      ...prev,
      [sectorIndex]: pageNumber,
    }));
  };
  useEffect(() => {
    if (Object.keys(personalisedPortData.ppd.companies).length > 0) {
      setIsCompanyDetails(personalisedPortData.ppd.companies);
    } else {
      setIsCompanyDetails(CompanyDetails);
    }
    console.log('isCompanyDetails', Object.keys(personalisedPortData.ppd.companies).length);
  }, [personalisedPortData, CompanyDetails]);

  return (
    <Box width="100%" p={4} >
      <Box>
      <Accordion defaultIndex={[0]} key={JSON.stringify(isCompanyDetails)} className='company-accordion'  allowMultiple border="1px solid"  borderColor="avenirGrey.200">
        { Object.entries(isCompanyDetails).map(([sectorName, companies], sectorIndex) => {
          const totalPages = Math.ceil(companies.length / itemsPerPage);
          const currentPage = currentPages[sectorIndex];
          const firstItemIndex = (currentPage - 1) * itemsPerPage;
          const lastItemIndex = firstItemIndex + itemsPerPage;
          const currentItems = companies.slice(firstItemIndex, lastItemIndex);

          return (
            <AccordionItem key={sectorIndex}>
              <h2>
                <AccordionButton bg='avenirPurple.200' color= 'white' _hover={{ bg: 'avenirPurple.200' }}>
                  <Box flex="1" textAlign="left" sx={{textStyle: 'medium'}}>
                    {sectorName}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th sx={{textStyle: 'small'}} color="avenirGrey.500">Company Name</Th>
                      <Th sx={{textStyle: 'small'}} color="avenirGrey.500">Isin</Th>
                      <Th sx={{textStyle: 'small'}} color="avenirGrey.500" isNumeric>Weights</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {currentItems.map((company, companyIndex) => (
                      <Tr key={companyIndex}>
                        <Td>{company.name}</Td>
                        <Td>{company.isin}</Td>
                        <Td isNumeric>{(company.weight).toFixed(2)}%</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
                {totalPages > 1 && (
                  <Flex mt={4} justifyContent="center" alignItems="center">
                    <Button onClick={() => setPage(sectorIndex, currentPage - 1)} disabled={currentPage === 1}>
                      {'<'}
                    </Button>
                    <Text mx="8" sx={{textStyle: 'medium'}}>
                       {currentPage}/{totalPages}
                    </Text>
                    <Button onClick={() => setPage(sectorIndex, currentPage + 1)} disabled={currentPage === totalPages}>
                      {'>'}
                    </Button>
                  </Flex>
                )}
              </AccordionPanel>
            </AccordionItem>
          );
        })}
      </Accordion>
      </Box>
    </Box>
  );
}

export default CompanyDetails;
