import './assets/css/App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import AuthLayout from './layouts/auth';
import AdminLayout from './layouts/admin';
import RTLLayout from './layouts/rtl';
import ValueMapping from './layouts/value-mapping'
import {
  ChakraProvider,
  // extendTheme
} from '@chakra-ui/react';
import initialTheme from './theme/theme'; //  { themeGreen }
import { useState } from 'react';
// Chakra imports

//Firebase imports
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBUUAWK6I-arNQEShBxyETyAYvSJSnMc1M",
  authDomain: "valuemapping-df5f2.firebaseapp.com",
  projectId: "valuemapping-df5f2",
  storageBucket: "valuemapping-df5f2.appspot.com",
  messagingSenderId: "275273040053",
  appId: "1:275273040053:web:fb9d19f0c41e7ea64b3efc",
  measurementId: "G-Q7H4P73WVN"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
// eslint-disable-next-line no-unused-vars
const analytics = getAnalytics(app);
export default function Main() {
  const [currentTheme, setCurrentTheme] = useState(initialTheme); 
  return (
    <ChakraProvider theme={currentTheme}>
      <Routes>
        <Route path="auth/*" element={<AuthLayout />} />
        <Route path="value-mapping/*" element={<ValueMapping theme={currentTheme} setTheme={setCurrentTheme} />} />
        <Route
          path="admin/*"
          element={
            <AdminLayout theme={currentTheme} setTheme={setCurrentTheme} />
          }
        />
        <Route
          path="rtl/*"
          element={
            <RTLLayout theme={currentTheme} setTheme={setCurrentTheme} />
          }
        />
        <Route path="/" element={<Navigate to="/value-mapping" replace />} />
      </Routes>
    </ChakraProvider>
  );
}
