import { mode } from "@chakra-ui/theme-tools";
export const sliderStyles = {
  components: {
    RangeSlider: {
      // baseStyle: {
      //   thumb: {
      //     fontWeight: 400,
      //   },
      //   track: {
      //     display: "flex",
      //   },
      // },

      variants: {
        main: (props) => ({
          thumb: {
            bg: mode("brand.500", "brand.400")(props),
          },
        }),
      },
    },
    Stepper: {
      baseStyle: (props) => ({
        container: {
          bg: props.colorMode === 'dark' ? 'purple.50' : 'purple.50',
        },
      }),
      defaultProps: {
        colorScheme: 'avenirPurple', 
      },
    },
    Progress: {
      baseStyle: (props) => ({
        filledTrack: {
          bg: props.colorMode === 'dark' ? 'avenirOrange.600' : 'avenirOrange.600',
        },
      }),
    },
  },
};
