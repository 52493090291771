import React, { useState, useRef } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setPersonalisedPortfolioData } from 'reducers/actions';
import LoadingModal from 'components/loadingModal';
import {
  Box,
  Tag,
  TagLabel,
  TagCloseButton,
  Input,
  Stack,
  InputGroup,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Portal,
  Text,
  useDisclosure,
  Button,
} from '@chakra-ui/react';
import { AddIcon, InfoOutlineIcon, MinusIcon } from '@chakra-ui/icons';
import { API_PORTFOLIO_URL } from 'static/constants';

const CompanyDetails = () => {
  const dispatch = useDispatch();
  const [modalState, setmodalState] = useState(0);
  const [includedCompanies, setIncludedCompanies] = useState([]);
  const [excludedCompanies, setExcludedCompanies] = useState([]);
  const [includeInput, setIncludeInput] = useState('');
  const [excludeInput, setExcludeInput] = useState('');
  const includeDisclosure = useDisclosure();
  const excludeDisclosure = useDisclosure();
  const menuButtonClicked = useRef(false);
  const financialData = useSelector((state) => state);
  const allCompanies = Object.values(financialData.my.companies).flatMap(
    (sector) => sector.map((company) => company.name),
  );
  const removeCompany = (company, type) => {
    if (type === 'include') {
      setIncludedCompanies(includedCompanies.filter((c) => c !== company));
    } else {
      setExcludedCompanies(excludedCompanies.filter((c) => c !== company));
    }
  };

  const handleIncludeCompany = (company) => {
    if (
      !includedCompanies.includes(company) &&
      allCompanies.includes(company)
    ) {
      setIncludedCompanies((prev) => [...prev, company]);
      setExcludedCompanies((prev) => prev.filter((c) => c !== company));
      setIncludeInput('');
    }
    includeDisclosure.onClose();
  };

  const handleExcludeCompany = (company) => {
    if (
      !excludedCompanies.includes(company) &&
      allCompanies.includes(company)
    ) {
      setExcludedCompanies((prev) => [...prev, company]);
      setIncludedCompanies((prev) => prev.filter((c) => c !== company));
      setExcludeInput('');
    }
    excludeDisclosure.onClose();
  };

  const getFilteredCompanies = (type, input) => {
    return allCompanies.filter(
      (company) =>
        (type === 'include'
          ? !includedCompanies.includes(company)
          : !excludedCompanies.includes(company)) &&
        company.toLowerCase().startsWith(input.toLowerCase()),
    );
  };

  const updateInput = (value, type) => {
    if (type === 'include') {
      setIncludeInput(value);
      if (!menuButtonClicked.current) {
        value ? includeDisclosure.onOpen() : includeDisclosure.onClose();
      }
    } else {
      setExcludeInput(value);
      if (!menuButtonClicked.current) {
        value ? excludeDisclosure.onOpen() : excludeDisclosure.onClose();
      }
    }
    menuButtonClicked.current = false;
  };
  const resetAll = () => {
    setIncludedCompanies([]);
    setExcludedCompanies([]);
    // Reset inputs if necessary
    setIncludeInput('');
    setExcludeInput('');
    // Close any open menus if necessary
    includeDisclosure.onClose();
    excludeDisclosure.onClose();
  };
  const submitCompanies = () => {
    // Iterate through each sector
    const isins = [];
    Object.values(financialData.my.companies).forEach((sector) => {
      sector.forEach((company) => {
        if (excludedCompanies.includes(company.name)) {
          isins.push(company.isin);
        }
      });
    });
    postPortfolioData(financialData.cr.composition, isins);
  };
  const postPortfolioData = async (composition, isin) => {
    setmodalState(1);
    const apiUrl = API_PORTFOLIO_URL;
    let requestBody; 
    if (isin.length) {
      requestBody = {
        composition,
        exclusions: {
          themes: [],
          companies: isin,
        },
      };
    } else {
      requestBody = {
        composition,
      };
    }

    try {
      const response = await axios.post(apiUrl, requestBody, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setmodalState(0);
      dispatch(setPersonalisedPortfolioData(response.data));
      return response.data;
    } catch (error) {
      setmodalState(0);
      console.error('Error making the POST request:', error);
      throw error;
    }
  };
  return (
    <>
      <Box
        className="personal-comps"
        p={5}
        border="1px"
        borderColor="gray.200"
        borderRadius="lg"
        w={{ base: '90%', md: '45%' }}
        mt={{ base: '15px' }}
        h="max-content"
      >
        <Stack spacing={3}>
          {/* Other content remains unchanged */}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box fontSize="lg" fontWeight="semibold">
              Requirements Parameters
            </Box>
            <InfoOutlineIcon />
          </Box>
          {/* Include Section */}
          <Box>
            <Box mb={2} sx={{ textStyle: 'small' }}>
              Include
            </Box>
            <InputGroup bg="gray.100" borderRadius="lg">
              <Input
                placeholder="Enter criteria"
                value={includeInput}
                onChange={(e) => updateInput(e.target.value, 'include')}
                border="none"
              />
              <Menu
                isOpen={includeDisclosure.isOpen}
                onClose={includeDisclosure.onClose}
              >
                <MenuButton
                  as={IconButton}
                  aria-label="Add company"
                  bg="none"
                  icon={<AddIcon />}
                />
                <Portal>
                  <MenuList>
                    {getFilteredCompanies('include', includeInput).length >
                    0 ? (
                      getFilteredCompanies('include', includeInput).map(
                        (company) => (
                          <MenuItem
                            key={company}
                            onClick={() => handleIncludeCompany(company)}
                          >
                            {company}
                          </MenuItem>
                        ),
                      )
                    ) : (
                      <Text px={4} py={2}>
                        No companies found
                      </Text>
                    )}
                  </MenuList>
                </Portal>
              </Menu>
            </InputGroup>
            {/* Tags for included companies */}
            <Stack direction="row" mt={2}>
              {includedCompanies.map((company) => (
                <Tag
                  size="md"
                  borderRadius="full"
                  variant="solid"
                  colorScheme="teal"
                  bg="green.50"
                  key={company}
                  p="8px 12px 8px 12px"
                >
                  <TagLabel color="green.600">{company}</TagLabel>
                  <TagCloseButton
                    color="green.600"
                    onClick={() => removeCompany(company, 'include')}
                  />
                </Tag>
              ))}
            </Stack>
          </Box>

          {/* Exclude Section */}
          <Box>
            <Box mb={2} sx={{ textStyle: 'small' }}>
              Exclude
            </Box>
            <InputGroup bg="gray.100" borderRadius="lg">
              <Input
                placeholder="Enter criteria"
                value={excludeInput}
                onChange={(e) => updateInput(e.target.value, 'exclude')}
                border="none"
              />
              <Menu
                isOpen={excludeDisclosure.isOpen}
                onClose={excludeDisclosure.onClose}
              >
                <MenuButton
                  as={IconButton}
                  aria-label="Add company"
                  bg="none"
                  icon={<MinusIcon />}
                />
                <Portal>
                  <MenuList>
                    {getFilteredCompanies('exclude', excludeInput).length >
                    0 ? (
                      getFilteredCompanies('exclude', excludeInput).map(
                        (company) => (
                          <MenuItem
                            key={company}
                            onClick={() => handleExcludeCompany(company)}
                          >
                            {company}
                          </MenuItem>
                        ),
                      )
                    ) : (
                      <Text px={4} py={2}>
                        No companies found
                      </Text>
                    )}
                  </MenuList>
                </Portal>
              </Menu>
            </InputGroup>
            {/* Tags for excluded companies */}
            <Stack direction="row" mt={2} flexWrap="wrap" rowGap="8px">
              {excludedCompanies.map((company) => (
                <Tag
                  size="md"
                  borderRadius="full"
                  variant="solid"
                  colorScheme="pink"
                  bg="pink.50"
                  key={company}
                  p="8px 12px"
                >
                  <TagLabel color="pink.600">{company}</TagLabel>
                  <TagCloseButton
                    color="pink.600"
                    onClick={() => removeCompany(company, 'exclude')}
                  />
                </Tag>
              ))}
            </Stack>
          </Box>
        </Stack>
        <Box mt="15px">
          <Button
            colorScheme="purple"
            bg="avenirPurple.600"
            variant="solid"
            p="16px 24px 16px 24px"
            borderRadius="10px"
            w="47%"
            onClick={() => submitCompanies()}
          >
            Submit
          </Button>
          <Button
            variant="outline"
            borderRadius="10px"
            w="47%"
            ml="10px"
            onClick={resetAll}
          >
            Reset all
          </Button>
        </Box>
      </Box>
      {modalState === 1 && <LoadingModal></LoadingModal>}
    </>
  );
};

export default CompanyDetails;
