import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Select } from '@chakra-ui/react';
import LineChart from 'components/charts/LineChart';

// Custom components
import Card from 'components/card/Card';

export default function OverallRevenue(investmentHistory) {
  const { ...rest } = investmentHistory;
  const personalisedPortData = useSelector((state) => state);
  const { dates, values } = investmentHistory.investmentHistory;
  const { values: personalisedValues } =
    personalisedPortData.ppd.investmentHistory;
  const [xcategories, setCategories] = useState(dates.slice(-30));
  const [graphData, setgraphData] = useState(values.slice(-30));
  const [personalisedGraphData, setpersonalisedGraphData] = useState(
    personalisedValues.slice(-30),
  );
  const handleSelectChange = (event) => {
    const period = event.target.value;
    let newCategories, newGraphData, newpersonalisedGraphData;
    switch (period) {
      case 'monthly':
        newCategories = dates.slice(-30);
        newGraphData = values.slice(-30);
        newpersonalisedGraphData = personalisedValues.slice(-30);
        break;
      case 'yearly':
        newCategories = dates.slice(-365);
        newGraphData = values.slice(-365);
        newpersonalisedGraphData = personalisedValues.slice(-365);
        break;
      case 'all-time':
        newCategories = dates;
        newGraphData = values;
        newpersonalisedGraphData = personalisedValues;
        break;
      default:
        newCategories = dates.slice(-30);
        newGraphData = values.slice(-30);
        newpersonalisedGraphData = personalisedValues.slice(-30);
    }
    setCategories(newCategories);
    setgraphData(newGraphData);
    setpersonalisedGraphData(newpersonalisedGraphData);
  };
  const [lineChartDataOverallRevenue, setlineChartDataOverallRevenue] = useState([
    {
      name: 'Original Portfolio values',
      data: graphData,
    },
    ...(personalisedGraphData.length
      ? [
          {
            name: 'Updated Portfolio values',
            data: personalisedGraphData,
          },
        ]
      : []),
  ]);
  const [lineChartOptionsOverallRevenue, setlineChartOptionsOverallRevenue] =
    useState({
      chart: {
        toolbar: {
          show: false,
        },
        dropShadow: {
          enabled: false,
          top: 13,
          left: 0,
          blur: 10,
          opacity: 0.1,
          color: '#4318FF',
        },
      },
      colors: ['#4318FF', '#39B8FF'],
      markers: {
        size: 0,
        colors: 'white',
        strokeColors: '#7551FF',
        strokeWidth: 3,
        strokeOpacity: 0.9,
        strokeDashArray: 0,
        fillOpacity: 1,
        discrete: [],
        shape: 'circle',
        radius: 2,
        offsetX: 0,
        offsetY: 0,
        showNullDataPoints: true,
      },
      tooltip: {
        theme: 'dark',
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
        type: 'line',
        width: 1,
      },
      xaxis: {
        type: 'numeric',
        categories: xcategories,
        labels: {
          style: {
            colors: '#A3AED0',
            fontSize: '12px',
            fontWeight: '500',
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        show: false,       
      },
      legend: {
        show: false,
      },
      grid: {
        show: false,
        column: {
          color: ['#7551FF', '#39B8FF'],
          opacity: 0.5,
        },
      },
      color: ['#7551FF', '#39B8FF'],
    });
    function formatNumbers(numbers) {
      return numbers.map(number => 
        (parseFloat(number).toFixed(2))
      );
    }
  useEffect(() => {
    // Update chart options whenever xcategories changes
    setlineChartOptionsOverallRevenue((prevOptions) => ({
      ...prevOptions,
      xaxis: {
        ...prevOptions.xaxis,
        categories: xcategories,
      },
    }));
  }, [xcategories]);
  useEffect(() => {
    setpersonalisedGraphData(personalisedValues.slice(-30));
  }, [personalisedValues]);
  const newOptions = {
    ...lineChartOptionsOverallRevenue,
    colors: ['#FA6B25', '#B17EFF'],
  };
  useEffect(() => {
    if (personalisedGraphData != null) {
      setlineChartDataOverallRevenue([
        {
          name: 'Original Portfolio Values',
          data: formatNumbers(graphData),
        },
        {
          name: 'Updated Portfolio Values',
          data: formatNumbers(personalisedGraphData),
        }
      ]);
    } else {
      setlineChartDataOverallRevenue([
        {
          name: 'Original Portfolio Values',
          data: formatNumbers(graphData),
        }
      ]);
    }
  }, [graphData, personalisedGraphData]);
  // Chakra Color Mode

  return (
    <Card
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      w="100%"
      mb={{ base: '20px', lg: '0px' }}
      {...rest}
    >
      <Box minH="260px" mt="auto" w="100%">
        <LineChart
          chartData={lineChartDataOverallRevenue}
          chartOptions={newOptions}
          key={`${JSON.stringify(newOptions)}_${JSON.stringify(lineChartDataOverallRevenue)}`}
        />
      </Box>
      <div
        className="select_performance"
        style={{ width: '100%', display: 'flex', margin: '16px 0 0 -10px' }}
      >
        <Select
          fontSize="sm"
          variant="subtle"
          defaultValue="monthly"
          width="unset"
          fontWeight="700"
          onChange={handleSelectChange}
        >
          <option value="all-time">All time</option>
          <option value="yearly">Year</option>
          <option value="monthly">Month</option>
        </Select>
      </div>
    </Card>
  );
}
