import React, { useState, useEffect } from 'react';
import {  useSelector, useDispatch } from 'react-redux';
import { setCurrentStep } from 'reducers/actions';
// Chakra imports
import { Flex } from '@chakra-ui/react';
// Custom components
import AnnualPerformance from 'views/admin/dashboards/avenirDefault/components/AnnualPerformance';
import PerformanceRatios from 'views/admin/dashboards/avenirDefault/components/PerformanceRatios';
import EtfPortfolioPage from 'views/admin/dashboards/avenirDefault/components/EtfPortfolioPage';
import CompanyDetails from './components/CompanyDetails';
import PersnolisationComps from './components/PersnolisationComps';
import { useSteps, Text } from '@chakra-ui/react';

//Assets
import { AnnualGrowth, AnnualMenu, AnnualUp } from 'components/icons/Icons';

const steps = [
  { title: 'Client Selection', description: '' },
  { title: 'Parameter Selection', description: '' },
  { title: 'Portfolio Selection', description: '' },
  { title: 'Personalisation', description: '' },
  { title: 'Validation', description: '' },
];
export default function Default() {
  // Chakra Color Mode
  const financialData = useSelector((state) => state);
  const dispatch = useDispatch();
  const { activeStep, setActiveStep } = useSteps({
    index: financialData.cs.currentStep,
    count: steps.length,
  });
  const [activeIndex, setActiveIndex] = useState(1);
  const [portfolioApiResponse, setportfolioApiResponse] = useState();
  const [etfRowDetails, setEtfRowDetails] = useState(
    Array.from({ length: 4 }, () => ({ isin: '', weight: '' })),
  );
  const estimatedAnnualPerformance = () => {
    if (Object.keys(financialData.ppd.ratios).length) {
      return(financialData.ppd.ratios[3].value);
    } else {
      return(portfolioApiResponse.ratios[3].value);
    }
  };
  useEffect(() => {
    setActiveStep(financialData.cs.currentStep)
  }, [financialData.cs.currentStep, setActiveStep]);
  return (
    <>
      {activeStep === 3 && (
        <Flex
          className="main_page"
          direction={{ base: 'column', xl: 'row' }}
          pt={{ base: '120px', md: '20px', xl: '20px' }}
        >
          <Flex
            className="left_panel"
            direction="column"
            width="stretch"
            border="1px solid"
            borderColor="#E6E6E6"
            p="24px"
            borderRadius="16px"
            bg="avenirWhite"
            marginRight="25px"
          >
            <Flex
              className="annual-performance-options"
              justifyContent="space-between"
            >
              <Flex align="center">
                <Flex flexDirection="column" me="20px">
                  <Text
                    color="avenirBlack"
                    fontSize="sm"
                    fontWeight="500"
                    mb="4px"
                    sx={{ textStyle: 'large' }}
                  >
                    Estimated Annual Performance
                  </Text>
                  <Text
                    color={portfolioApiResponse.ratios[3].value > 0 ? 'avenirGreen.600' : 'avenirRed.600'}
                    fontSize="34px"
                    fontWeight="700"
                    lineHeight="100%"
                    mb="10px"
                  >
                    { (estimatedAnnualPerformance()*100).toFixed(2) }%
                  </Text>
                </Flex>
              </Flex>
              <Flex alignSelf="flex-start">
                <AnnualGrowth
                  h="40px"
                  w="40px"
                  borderRadius="8px"
                  cursor="pointer"
                  ml="10px"
                  bg={activeIndex === 1 ? 'avenirPurple.600' : 'gray.200'}
                  fill={activeIndex === 1 ? 'avenirWhite' : 'avenirGrey.600'}
                  onClick={() => setActiveIndex(1)}
                ></AnnualGrowth>
                <AnnualUp
                  h="40px"
                  w="40px"
                  borderRadius="8px"
                  cursor="pointer"
                  ml="10px"
                  bg={activeIndex === 2 ? 'avenirPurple.600' : 'gray.200'}
                  fill={activeIndex === 2 ? 'avenirWhite' : 'avenirGrey.600'}
                  onClick={() => {setActiveIndex(2);}}
                ></AnnualUp>
                <AnnualMenu
                  h="40px"
                  w="40px"
                  borderRadius="8px"
                  cursor="pointer"
                  ml="10px"
                  bg={activeIndex === 3 ? 'avenirPurple.600' : 'gray.200'}
                  fill={activeIndex === 3 ? 'avenirWhite' : 'avenirGrey.600'}
                  onClick={() => setActiveIndex(3)}
                ></AnnualMenu>
              </Flex>
            </Flex>
            {activeIndex === 1 && (
              <Flex
                gridArea={{ base: '1 / 1 / 2 / 3', '2xl': '1 / 1 / 2 / 2' }}
              >
                <AnnualPerformance investmentHistory={portfolioApiResponse.investmentHistory}/>
              </Flex>
            )}
            {activeIndex === 2 && (
              <Flex
                gridArea={{ base: '1 / 1 / 2 / 3', '2xl': '1 / 1 / 2 / 2' }}
              >
                <PerformanceRatios ratios={portfolioApiResponse.ratios} />
              </Flex>
            )}
            {activeIndex === 3 && (
              <Flex
                gridArea={{ base: '1 / 1 / 2 / 3', '2xl': '1 / 1 / 2 / 2' }}
              >
                <CompanyDetails CompanyDetails={portfolioApiResponse.companies}/>
              </Flex>
            )}
          </Flex>

          <PersnolisationComps></PersnolisationComps>
        </Flex>
      )}
      {activeStep === 2 && (
        <EtfPortfolioPage
          etfRowDetails={etfRowDetails}
          setEtfRowDetails={setEtfRowDetails}
          portfolioApiResponse={portfolioApiResponse}
          setportfolioApiResponse={setportfolioApiResponse}
          onSubmitSuccess={() => dispatch(setCurrentStep(3))}
        ></EtfPortfolioPage>
      )}
    </>
  );
}
