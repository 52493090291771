// Action Type
export const SET_FINANCIAL_DATA = 'SET_FINANCIAL_DATA';
export const SET_COMPOSITION = 'SET_COMPOSITION';
export const SET_PERSONALISED_PORTFOLIO_DATA = 'SET_PERSONALISED_PORTFOLIO_DATA';
export const SET_CURRENT_STEP = 'SET_CURRENT_STEP';

// Action Creator
export const setFinancialData = (data) => ({
  type: SET_FINANCIAL_DATA,
  payload: data,
});

export const setComposition = (composition) => ({
  type: SET_COMPOSITION,
  payload: composition,
});
export const setPersonalisedPortfolioData = (data) => ({
  type: SET_PERSONALISED_PORTFOLIO_DATA,
  payload: data,
});
export const setCurrentStep = (step) => ({
  type: SET_CURRENT_STEP,
  payload: step,
});
