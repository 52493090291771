import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setFinancialData, setComposition } from 'reducers/actions';
import { Box, Text, VStack, HStack, Input, Button, Flex } from '@chakra-ui/react';
import axios from 'axios';
import LoadingModal from 'components/loadingModal';
import { API_PORTFOLIO_URL } from 'static/constants';

export default function Default({ etfRowDetails, setEtfRowDetails, onSubmitSuccess,portfolioApiResponse,setportfolioApiResponse }) {
    // Initialize etfRowDetails with 4 empty rows
    const dispatch = useDispatch();

    const handleInputChange = (index, field, value) => {
        const newEtfRowDetails = etfRowDetails.map((row, idx) => {
            if (idx === index) {
                return { ...row, [field]: value };
            }
            return row;
        });
        setEtfRowDetails(newEtfRowDetails);
    };
    const [modalState, setmodalState] = useState(0);

    const handleAddRow = () => {
        setEtfRowDetails([...etfRowDetails, { isin: '', weight: '' }]);
        console.log(etfRowDetails);
    };

    const handleResetRows = () => {
        setEtfRowDetails(Array.from({ length: 4 }, () => ({ isin: '', weight: '' })));
        console.log(etfRowDetails);
    };
    const totalWeight = etfRowDetails.reduce((acc, row) => acc + parseFloat(row.weight || 0), 0);
    const handleSubmit = async () => {
        setmodalState(1);
        const composition = etfRowDetails.reduce((acc, { isin, weight }) => {
            if (isin && weight) {
                acc[isin] = parseFloat(weight);
            }
            return acc;
        }, {});
        try {
            const response = await axios.post(API_PORTFOLIO_URL, {
                composition: composition
            }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            dispatch(setFinancialData(response.data));
            dispatch(setComposition(composition));
            setportfolioApiResponse(response.data);
            setmodalState(0);
            onSubmitSuccess();
        } catch (error) {
            setmodalState(0);
            console.error('Submission failed:', error);
        }
    };
    return (
        <>
        <Flex
        className="main_page"
        direction={{ base: 'column', xl: 'row' }}
        pt={{ base: '120px', md: '20px', xl: '20px' }}
      >
        <Box
            p={5}
            shadow="md"
            borderWidth="1px"
            borderRadius="lg"
            maxWidth="600px"
            mx="auto"
            my="20px"
            w="70%"
        >
            <VStack spacing={4}>
                <HStack justifyContent="space-between" w="full" px="2">
                    <Text fontSize="md" fontWeight="semibold" w="45%">
                        ISIN
                    </Text>
                    <Text fontSize="md" fontWeight="semibold" w="45%">
                        Weight
                    </Text>
                </HStack>
                {etfRowDetails.map((row, index) => (
                    <HStack key={index} w="full">
                        <Input
                            placeholder="ISIN"
                            w="45%"
                            value={row.isin}
                            onChange={(e) => handleInputChange(index, 'isin', e.target.value)}
                        />
                        <Input
                            placeholder="Weight"
                            w="45%"
                            value={row.weight}
                            onChange={(e) => handleInputChange(index, 'weight', e.target.value)}
                        />
                    </HStack>
                ))}
                <HStack pt={5}>
                    <Button
                        colorScheme="purple"
                        bg="avenirPurple.600"
                        variant="solid"
                        p="16px 24px 16px 24px"
                        onClick={handleAddRow}
                    >
                        Add
                    </Button>
                    <Button variant="outline" onClick={handleResetRows}>
                        Reset all
                    </Button>
                </HStack>
            </VStack>
        </Box>
        <Box
            p="24px 16px 24px 16px"
            w="30%"
            borderWidth="1px"
            borderRadius="lg"
            h="max-content"
          >
            <Button
              colorScheme="purple"
              bg="avenirPurple.600"
              variant="solid"
              w="100%"
            //   onClick={() => {
            //     setActiveStep(3);
            //   }}
            isDisabled={totalWeight !== 1}
            onClick={handleSubmit}
            >
              Submit
            </Button>
          </Box>
    </Flex>
    {modalState === 1 && (<LoadingModal></LoadingModal>)}
    </>
    );
}
