import React from 'react';
import { useSelector } from 'react-redux';
import {
    Table,
    Tbody,
    Tr,
    Td,
    TableContainer,
    Box,
  } from '@chakra-ui/react'
import KpiRatiosOptions from './KpiRatiosOptions';

export default function PerformanceRatio({ ratios }) {
  const personalisedPortData = useSelector((state) => state);
  const newRatios = personalisedPortData && personalisedPortData.ppd && personalisedPortData.ppd.ratios && personalisedPortData.ppd.ratios.length > 0 ? personalisedPortData.ppd.ratios : ratios;
  return (
    <Box 
      borderRadius="16px" 
      border="1px solid" 
      borderColor="gray.200" 
      bg="white" 
      width="100%"
      p="15px"
      mt='15px'
    >
      <div>
        <div >
            <TableContainer  
              bg="white" 
              width="100%"
            >
                <Table variant='simple'>
                    <Tbody>
                        {newRatios.map((pair, index) => (
                        <Tr key={index}>
                            <Td 
                              sx={{textStyle: 'regular'}}
                            >{pair.name}</Td>
                            <Td 
                              sx={{textStyle: 'medium'}}
                            >{parseFloat(pair.value).toFixed(2)}</Td>
                            <Td>
                              <KpiRatiosOptions isGrowth={pair.value >= ratios[index].value} value={parseFloat((((pair.value/ratios[index].value)-1))*100).toFixed(2)}></KpiRatiosOptions>
                            </Td>
                        </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
        </div>
      </div>
    </Box>
  );
}
