// reducers/currentStepReducer.js
import { SET_CURRENT_STEP } from './actions';

const initialState = {
  currentStep: 2,
};

const currentStepReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_STEP:
      return {
        ...state,
        currentStep: action.payload,
      };
    default:
      return state;
  }
};

export default currentStepReducer;
