import React, { useEffect } from 'react';
import {  useSelector } from 'react-redux';
import {
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  Box,
} from '@chakra-ui/react';

const steps = [
  { title: 'Client Selection', description: '' },
  { title: 'Parameter Selection', description: '' },
  { title: 'Portfolio Selection', description: '' },
  { title: 'Personalisation', description: '' },
  { title: 'Validation', description: '' },
];

export default function Default() {
  const financialData = useSelector((state) => state);
  const { activeStep, setActiveStep } = useSteps({
    index: financialData.cs.currentStep,
    count: steps.length,
  });

  useEffect(() => {
    setActiveStep(financialData.cs.currentStep)
  }, [financialData.cs.currentStep, setActiveStep]);

  return(
    <Box
    className="stepper"
    marginTop="100px"
    border="1px solid"
    borderColor="#E6E6E6"
    p="24px"
    borderRadius="16px"
    bg="avenirWhite"
    w="95%"
    ml="28px"
    display={{ base: 'none', md: 'flex' }}
  >
    <Stepper index={activeStep} w="100%">
      {steps.map((step, index) => (
        <Step key={index}>
          <StepIndicator className="borderBorder">
            <StepStatus
              complete={
                <StepIcon
                  className="stepIcon"
                  bg="avenirPurple.50"
                  border="none"
                  width="100%"
                  height="100%"
                  borderRadius="50%"
                  color="avenirPurple.600"
                />
              }
              incomplete={<StepNumber />}
              active={
                <StepNumber
                  className="StepNumber"
                  bg="avenirPurple.600"
                  border="none"
                  width="100%"
                  height="100%"
                  borderRadius="50%"
                  display="grid"
                  placeItems="center"
                  borderColor="Yellow"
                />
              }
            />
          </StepIndicator>
          <Box flexShrink="0">
            <StepTitle>{step.title}</StepTitle>
            <StepDescription>{step.description}</StepDescription>
          </Box>
          <StepSeparator />
        </Step>
      ))}
    </Stepper>
  </Box>
  );
}
