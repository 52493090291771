import React, { useState } from 'react';
import { Box, Textarea, FormControl, FormLabel } from '@chakra-ui/react';

function TextTypeQuestions({ valueProps, valueQuestion }) {
  const VMapQuestion = valueQuestion;
  // eslint-disable-next-line no-unused-vars
  const { vMapQuestionsContext, updatevMapQuestionsContext } = valueProps;
  const [userTextInput, setuserTextInput] = useState(
    valueProps.vMapQuestionsContext[valueQuestion.id].answers[0],
  );
  const handleInputChange = (event) => {
    setuserTextInput(event.target.value);
    updatevMapQuestionsContext(valueQuestion.id, valueQuestion.question, [
      event.target.value,
    ]);
  };
  return (
    <Box p={5} width={{ base: '100%', xl: '70%' }} mt="15px">
      <FormControl>
        <FormLabel
          mb={{ base: '14px', xl: '36px' }}
          sx={{
            textStyle: { base: 'popinsmallHeading', xl: 'popinlarge' },
          }}
          fontSize="18px"
          fontWeight='600'
        >
          {VMapQuestion.question}
        </FormLabel>
        <Textarea
          id="cause-input"
          placeholder='Answer Here'
          borderRadius="20px"
          minHeight='70px'
          resize="none"
          overflow="hidden"
          _placeholder={{
            fontSize: 'md',
            color: 'gray.400',
          }}
          // _focus={{ bg:  }}
          p='22px 8px 8px 32px'
          onChange={handleInputChange}
          value={userTextInput}
        />
      </FormControl>
    </Box>
  );
}

export default TextTypeQuestions;
