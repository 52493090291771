import React, { useState, useEffect } from 'react';
import { Flex, HStack, Icon, Text, VStack } from '@chakra-ui/react';
import { FaCircle } from 'react-icons/fa';

const DotSelector = ({ value,topicColor, onChange }) => {
  const handleClick = (newValue) => {
    if (onChange) {
      onChange(newValue);
    }
  };
  return (
    <HStack spacing={2}>
      {[...Array(5)].map((_, index) => (
        <Icon
          as={FaCircle}
          key={index}
          cursor="pointer"
          color={index < value ? topicColor : 'gray.300'}
          onClick={() => handleClick(index + 1)}
        />
      ))}
    </HStack>
  );
};

const EmphasizeTopics = ({ valueProps, valueQuestion }) => {
  const [topics, setTopics] = useState([]);
  const [values, setValues] = useState(Array(8).fill(0));
  const [countValues, setcountValues] = useState(0);
  const handleValueChange = (index) => (newValue) => {
    const newValues = [...values];
    let num = Math.abs(newValue - newValues[index])
    let countedValue = (num + countValues) <= 16 ? newValue : (16-countValues)
    newValues[index] = countedValue;
    setValues(newValues);
  };
  useEffect(() =>{
    let counter =  0
    values.forEach((val) =>{
      counter +=val
    }) 
    setcountValues(counter)
    valueProps.updatevMapQuestionsContext(valueQuestion.id, valueQuestion.question, values);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[values])
  useEffect(() => {
    let newTopics = [];
    newTopics = newTopics.concat(valueProps.vMapQuestionsContext[11].answers);
    newTopics = newTopics.concat(valueProps.vMapQuestionsContext[12].answers);
    newTopics = newTopics.concat(valueProps.vMapQuestionsContext[13].answers);
    setTopics(newTopics);
  }, [valueProps]);
  const colorPicker = (topic) => {
   if(valueProps.vMapQuestionsContext[11].answers.includes(topic)){
    return "#E7D6FF";
   } else if (valueProps.vMapQuestionsContext[12].answers.includes(topic)){
    return "#FFF0D4";
   } else {
    return "#FFE3D4";
   }
  }
  useEffect(() => {
    const userAnswered = valueProps.vMapQuestionsContext[valueQuestion.id].answers
    const sum = userAnswered.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);
    if(sum > 0){
      setValues(userAnswered)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <VStack spacing={4} align="stretch" p={5}  width={{ base: '100%', xl: '70%' }}>
      <Text sx={{
          textStyle: { base: 'popinsmallHeading', xl: 'popinlarge' },
        }} fontWeight="bold">
        {valueQuestion.question}
      </Text>
      <Flex justifyContent="space-between" alignItems="center">
       <Text mb={6} sx={{textStyle: 'ellipsis'}} fontSize={{base: '12px', xl:'18px'}}>You can use 16 points in total</Text>
       <Text mb={6} sx={{textStyle: 'ellipsis'}} fontSize={{base: '12px', xl:'18px'}} fontWeight="600">Used {countValues}/16</Text>
      </Flex>
      <Flex flexDirection="column" rowGap="16px" h="60vh" overflow="scroll" pb={{ base: '80px', xl: '0' }}>
        {topics.map((topic, index) => (
          <Flex
            key={topic}
            p={5}
            borderWidth="1px"
            borderRadius="24px"
            padding={{ base: "15px 20px 15px 31px", xl: "20px 25px 20px 36px" }}
            justifyContent="space-between"
            alignItems="center"
            borderColor= {colorPicker(topic)}
            bgGradient={`linear(to-r, ${colorPicker(topic)} 20px, white 20px)`}
          >
            <Text sx={{textStyle: 'medium'}}>{topic}</Text>
            <DotSelector
              value={values[index]}
              topicColor = {colorPicker(topic)}
              onChange={handleValueChange(index)}
            />
          </Flex>
        ))}
      </Flex>
    </VStack>
  );
};

export default EmphasizeTopics;
