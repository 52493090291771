import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react';

function LoadingModal() {
  const { isOpen } = useDisclosure({ defaultIsOpen: true });

  return (
    <Modal isOpen={isOpen} isCentered >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader ml="70px">Loading your portfolio...</ModalHeader>
        <ModalBody display="flex" alignItems="center" justifyContent="center">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default LoadingModal;
