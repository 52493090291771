import React, { useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  CheckboxGroup,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react';

function McqTypeQuestions({ valueProps, valueQuestion }) {
  const { vMapQuestionsContext, updatevMapQuestionsContext } = valueProps;
  const [checkedItems, setCheckedItems] = useState([]);
  const [isOtherOpen, setisOtherOpen] = useState(false);
  const [userTextInput, setuserTextInput] = useState('');
  const VMapQuestion = valueQuestion;
  const vMapAnswers = {
    answer:
      vMapQuestionsContext[6].answers[0] === 'Yes'
        ? VMapQuestion.answeresYes
        : VMapQuestion.answeresNo,
  };
  const vMapYSQuestion = {
    question:
      vMapQuestionsContext[6].answers[0] === 'Yes'
        ? 'Why have you felt discouraged?'
        : 'Why have you never felt discouraged?',
  };
  const vMapYNPlaceHoledr = {
    placeholder:
      vMapQuestionsContext[6].answers[0] === 'Yes'
        ? 'I felt discourageed....'
        : 'I never felt discouraged because...',
  };
  const handleInputChange = (event) => {
    setuserTextInput(event.target.value);
    updatevMapQuestionsContext(valueQuestion.id, valueQuestion.question, [
      event.target.value,
    ]);
  };
  const handleCheckChange = (value) => {
    if (value === 'Other') {
      setisOtherOpen(true);
      setCheckedItems([value]);
      updatevMapQuestionsContext(valueQuestion.id, valueQuestion.question, [
        value,
      ]);
    } else {
      if ([...checkedItems, value].includes('Other')) {
        setCheckedItems([value]);
        updatevMapQuestionsContext(valueQuestion.id, valueQuestion.question, [
          value,
        ]);
        
      } else {
        setCheckedItems(currentItems => {
          const isValuePresent = currentItems.includes(value);
          const newCheckedItems = isValuePresent
            ? currentItems.filter(item => item !== value) 
            : [...currentItems, value]; 
          updatevMapQuestionsContext(valueQuestion.id, valueQuestion.question, newCheckedItems);
          return newCheckedItems;
        });
      }
      setisOtherOpen(false);
    }
  };
  const OptionPresent = () => {
    let isOptionPresent = false;
    VMapQuestion.answeresYes.forEach((element) => {
      if (
        element.value ===
        valueProps.vMapQuestionsContext[valueQuestion.id].answers[0]
      ) {
        isOptionPresent = true;
      }
    });
    VMapQuestion.answeresNo.forEach((element) => {
      if (
        element.value ===
        valueProps.vMapQuestionsContext[valueQuestion.id].answers[0]
      ) {
        isOptionPresent = true;
      }
    });
    return isOptionPresent;
  };
  useEffect(() => {
    if (OptionPresent()) {
      setCheckedItems(
        valueProps.vMapQuestionsContext[valueQuestion.id].answers,
      );
    } else {
      if (valueProps.vMapQuestionsContext[valueQuestion.id].answers[0]) {
        setCheckedItems('Other');
        setisOtherOpen(true);
        setuserTextInput(
          valueProps.vMapQuestionsContext[valueQuestion.id].answers[0],
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box p={4} width={{ base: '100%', xl: '75%' }} mt="5px">
      <Text
        mb={{ base: '24px', xl: '36px' }}
        sx={{
          textStyle: { base: 'popinsmallHeading', xl: 'popinlarge' },
        }}
        fontSize="18px"
      >
        {vMapYSQuestion.question}
      </Text>
      <CheckboxGroup value={checkedItems} mt="25px">
        <Stack direction="column" rowGap="5px" h="70vh" overflow="scroll" pb={{ base: '80px', xl: '0' }}>
          {vMapAnswers.answer.map((answer, index) => (
            <Checkbox
              key={answer.id}
              value={answer.value}
              border="1px solid"
              borderColor="avenirPurple.600"
              width={{ base: '95%', xl: '85%' }}
              p={{ base: '16px 12px 16px 18px', xl: '20px 0 20px 30px' }}
              borderRadius="24px"
              onChange={() => handleCheckChange(answer.value)}
              sx={{ textStyle: 'popinsmall' }}
              _checked={{
                bg: 'avenirPurple.50',
                borderColor: 'purple.600',
                iconColor: 'purple.600',
              }}
              _focus={{
                boxShadow: 'none',
              }}
              colorScheme="purple"
              spacing={{base: '18px', xl: '32px'}}
            >
              {answer.value}
            </Checkbox>
          ))}
          {isOtherOpen ? (
            <Textarea
              id="cause-input"
              placeholder={vMapYNPlaceHoledr.placeholder}
              size="lg"
              border="1px solid"
              borderColor="avenirPurple.600"
              borderRadius="18px"
              width={{ base: '95%', xl: '85%' }}
              height="50px"
              resize="none"
              overflow="hidden"
              _placeholder={{
                fontSize: 'md',
                color: 'gray.400',
              }}
              mt="15px"
              p="15px 0 15px 28px"
              value={userTextInput}
              onChange={handleInputChange}
              sx={{ textStyle: 'popinsmall' }}
            />
          ) : null}
        </Stack>
      </CheckboxGroup>
    </Box>
  );
}

export default McqTypeQuestions;
