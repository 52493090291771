import { SET_COMPOSITION } from './actions';

const initialState = {
  composition: {}
};

const compositionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMPOSITION:
      return {
        ...state,
        composition: action.payload,
      };
    default:
      return state;
  }
};

export default compositionReducer;
